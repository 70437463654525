<template>
  <div>
    <div class="d-flex justify-content-between mb-1">
      <div>
        <router-link to="/business-it-support/create-issue">
          <b-button variant="primary">
            <span class="align-middle">Add New Issue</span>
          </b-button>
        </router-link>
      </div>
      <div>
        <router-link to="/business-it-support/deleted-issues">
          <b-button
            variant="danger"
            size="md"
          >
            <span class="align-middle">Show deleted Issues</span>
          </b-button>
        </router-link>
      </div>
    </div>
    <b-overlay
      :show="loader"
      rounded="sm"
      variant="dark"
      :opacity="0.1"
    >
      <b-card title="Issues">
        <b-table
          responsive="sm"
          :busy.sync="isBusy"
          :items="items"
          :fields="fields"
        >
          <template #table-busy>
            <div class="text-center text-success my-2">
              <b-spinner class="align-middle" />
              <strong> Loading...</strong>
            </div>
          </template>
          <template #cell(ID)="data">
            {{ data.index + 1 }}
          </template>
          <template #cell(name)="data">
            {{ data.value }}
          </template>
          <template #cell(description)="data">
            {{ data.value }}
          </template>
          <template #cell(average_time)="data">
            {{ data.value }}
          </template>
          <template #cell(actions)="data">
            <div class="d-flex">
              <router-link :to="'/business-it-support/update-issue/' + data.item.id">
                <b-button
                  variant="flat-warning"
                  class="btn-icon"
                >
                  <feather-icon icon="EditIcon" />
                </b-button>
              </router-link>
              <b-button
                variant="flat-danger"
                class="btn-icon"
                @click="deleteIssue(data.item)"
              >
                <feather-icon icon="DeleteIcon" />
              </b-button>
            </div>
          </template>
        </b-table>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const businessITSupportModule = createNamespacedHelpers('businessITSupport')

export default {
  data() {
    return {
      isBusy: false,
      loader: false,
      fields: [
        { key: 'ID', label: 'ID' },
        { key: 'name', label: 'name' },
        { key: 'description', label: 'description' },
        { key: 'average_time', label: 'Recommended time' },
        { key: 'actions', label: 'actions' },
      ],
      items: [],
    }
  },
  async mounted() {
    await this.getIssues()
  },
  methods: {
    ...businessITSupportModule.mapActions(['SHOW_ALL_BS_ISSUE', 'DELETE_BS_ISSUE']),

    async getIssues() {
      try {
        this.isBusy = true
        this.items = await this.SHOW_ALL_BS_ISSUE()
        this.isBusy = false
      } catch (error) {
        this.isBusy = false
      }
    },

    async deleteIssue(item) {
      this.$bvModal
        .msgBoxConfirm('Please confirm that you want to delete this issue', {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(async value => {
          if (value) {
            try {
              this.loader = true
              const resp = await this.DELETE_BS_ISSUE(item.id)
              if (resp) {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Issue deleted!',
                    icon: 'PlusIcon',
                    variant: 'success',
                    text: 'Issue has been deleted successfully!',
                  },
                })
                this.getIssues()
              }
              this.loader = false
            } catch (error) {
              this.loader = false
            }
          }
        })
    },
  },
}
</script>
